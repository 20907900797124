import { useState, useEffect } from 'react'


const Disclaimer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const hasAcceptedDisclaimer = localStorage.getItem('hasAcceptedDisclaimer');
    if (!hasAcceptedDisclaimer) {
      setIsModalOpen(true);
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    localStorage.setItem('hasAcceptedDisclaimer', 'true');
  };

 return (
  <div className={`${isModalOpen ? 'transform translate-y-0 delay-500 opacity-1 z-[200]' : 'transform translate-y-[3rem] opacity-0 z-[-5]'} fixed inset-0 flex items-center justify-center bg-[#ffffff]/40 overflow-auto transition duration-200 ease-in-out`}>
    <div className="flex flex-col items-center justify-center w-full max-w-[90%] md:max-w-[70%] lg:max-w-[50%] mx-auto">
      <p className="text-[.875rem] md:text-[1rem] lg:text-[1.25rem] text-secondary-light bg-background p-[2rem] rounded-tr-[2rem] rounded-tl-[2rem]">
        <b>AGREEMENT AND DISCLAIMER:</b> BY ACCESSING INFORMATION ABOUT STARSHIP TOKEN ($STSHIP) AND OTHER MATERIALS ON THIS SITE, YOU ACKNOWLEDGE AND ACCEPT THAT SUCH INFORMATION IS FOR INFORMATIONAL AND ENTERTAINMENT PURPOSES ONLY. IT IS NOT FINANCIAL, INVESTMENT, LEGAL, OR OTHER PROFESSIONAL ADVICE. WE ARE NOT REGISTERED INVESTMENT ADVISERS. DECISIONS BASED ON THIS CONTENT ARE AT YOUR OWN RISK. REVIEW AND COMPLIANCE WITH ANY JURISDICTIONAL RESTRICTIONS ARE YOUR RESPONSIBILITY.
      </p>
      <button className='w-full h-[5rem] flex justify-end p-[2rem] bg-background rounded-bl-[2rem] rounded-br-[2rem]' onClick={handleCloseModal}>
        <p className='text-secondary-dark  flex items-center font-[600] text-[.7rem] md:text-[1rem] h-[1.875rem] md:h-[2.5rem] px-[1.5rem] bg-secondary-light rounded-[5px] hover:bg-secondary active:text-[#ffffff]'>CONFIRM</p>
      </button>
    </div>
  </div>
);

}

export default Disclaimer